// https://stackoverflow.com/a/78764158

'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { createContext, useContext, useEffect, useState } from 'react'

export const useNavigationContext = () => useContext(NavigationContext)

export const NavigationProvider = ({ children }: { children: React.ReactNode }) => {
    const navigation = useNavigation()

    return <NavigationContext.Provider value={navigation}>{children}</NavigationContext.Provider>
}

const useNavigation = () => {
    const pathname = usePathname()
    const searchParams = useSearchParams()

    const [currentRoute, setCurrentRoute] = useState<string | null>(null)
    const [previousRoute, setPreviousRoute] = useState<string | null>(null)

    useEffect(() => {
        let url: string
        if (searchParams.size) url = `${pathname}?${searchParams}`
        else url = pathname

        if (!currentRoute || currentRoute !== url) setPreviousRoute(currentRoute)

        setCurrentRoute(url)
    }, [pathname, currentRoute, searchParams])

    return { previousRoute }
}

const NavigationContext = createContext<ReturnType<typeof useNavigation>>({
    previousRoute: null,
})
