'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { Email, ID } from '@repo/types/user/User'
import { Button } from '@repo/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@repo/ui/card'
import { Form, FormControl, FormDescription, FormField, FormItem, FormMessage } from '@repo/ui/form'
import { Input } from '@repo/ui/input'
import { status as StatusCodes } from 'http-status'
import { GitBranch, Mail } from 'lucide-react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { login } from 'actions/auth'
import { cn } from '@repo/ui/lib/utils'
import { useToast } from '@repo/ui/hooks/use-toast'
import { ToastAction } from '@repo/ui/toast'
import { useRef } from 'react'
import { useNavigationContext } from 'components/navigation/navigation-provider'

const SCHEMA = z.object({
    id: z.union(
        [
            z.string().min(ID.Length.Min, { message: '' }).regex(ID.Regex, { message: 'Must be a valid user ID.' }),
            z.string().email({ message: 'Must be a valid email address.' }),
        ],
        { message: 'Must be a valid user ID or email address.' }
    ),

    passwd: z.string({ required_error: 'Password is required' }).min(1, { message: '' }),
})

export default function LoginPage({ router }: { router?: ReturnType<typeof useRouter> }) {
    const { toast, dismiss } = useToast()
    const toastId = useRef<string | null>(null)

    if (router === undefined) router = useRouter()
    const { previousRoute } = useNavigationContext()

    const t = useTranslations('login')

    const form = useForm<z.infer<typeof SCHEMA>>({
        mode: 'onChange',

        resolver: zodResolver(SCHEMA),
        defaultValues: {
            id: '',
            passwd: '',
        },
    })

    const {
        formState: { isSubmitting, isDirty, isValid, errors },
        setError,
        resetField,
    } = form

    async function onSubmit(values: z.infer<typeof SCHEMA>) {
        if (toastId.current) dismiss(toastId.current)

        let { ok, status } = new Response(undefined, { status: StatusCodes.SERVICE_UNAVAILABLE })

        const resp = await login(values)
        if (resp) ({ ok, status } = resp)

        if (ok) {
            toastId.current = toast({
                title: 'Welcome!',
                description: `You successfully logged in as @${values.id}.`,
                duration: 1500,
            }).id

            if (!router) return

            if (previousRoute !== null && (previousRoute === '/logout' || previousRoute === '/login'))
                return router.back()

            return router.refresh()
        }

        let message = t('message', { status })
        if (!message) message = StatusCodes[`${status as keyof typeof StatusCodes & number}_MESSAGE`]

        if (status === StatusCodes.NOT_FOUND) {
            setError('id', { type: status.toString(), message }, { shouldFocus: true })
        } else if (status === StatusCodes.FORBIDDEN || status === StatusCodes.UNAUTHORIZED) {
            setError('passwd', { type: status.toString(), message }, { shouldFocus: true })
            resetField('passwd', { keepError: true })
        } else {
            setError('root', { type: status.toString(), message })

            toastId.current = toast({
                title: 'Something went wrong.',
                description: (
                    <>
                        There was a problem with your request. We might be experiencing{' '}
                        <Link className='underline' href='/status'>
                            some problems
                        </Link>
                    </>
                ),
                action: (
                    <ToastAction altText='Try again' onClick={form.handleSubmit(onSubmit)}>
                        Try again
                    </ToastAction>
                ),
                duration: 5_000,
            }).id
        }
    }

    return (
        <div className='flex justify-center items-start md:items-center p-8'>
            <Card className='w-full max-w-sm'>
                <CardHeader>
                    <CardTitle className='text-2xl'>{t('title')}</CardTitle>
                </CardHeader>

                <CardContent className='space-y-4'>
                    <Form {...form}>
                        <form
                            onSubmit={form.handleSubmit(onSubmit)}
                            className='grid grid-cols-1 gap-4 justify-center items-start'
                        >
                            <FormField
                                control={form.control}
                                name='id'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                autoFocus={true}
                                                type='text'
                                                placeholder={t('form.id.placeholder')}
                                                className={cn(errors.id ? 'border-red-500' : undefined)}
                                            />
                                        </FormControl>

                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name='passwd'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                type='password'
                                                placeholder={t('form.passwd.placeholder')}
                                                className={cn(errors.passwd ? 'border-red-500' : undefined)}
                                            />
                                        </FormControl>

                                        <FormMessage />

                                        <FormDescription>
                                            <Link href='/forgot' className='underline'>
                                                {t('form.passwd.forgot')}
                                            </Link>
                                        </FormDescription>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                name='root'
                                render={({ field }) => (
                                    <FormItem>
                                        <Button
                                            type='submit'
                                            disabled={isSubmitting || !isDirty || !isValid}
                                            className='w-full mt-2'
                                        >
                                            {t('form.submit.id')}
                                        </Button>

                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </form>
                    </Form>

                    <div className='grid grid-cols-2 gap-4 items-center justify-items-center'>
                        <Button disabled={true || isSubmitting} title={t('form.submit.google')}>
                            <Mail className='w-6 h-6' /> Google
                        </Button>

                        <Button disabled={true || isSubmitting} title={t('form.submit.github')}>
                            <GitBranch className='w-6 h-6' /> GitHub
                        </Button>
                    </div>
                </CardContent>

                <CardFooter className='text-center text-primary'>
                    {t.rich('signup', {
                        link: text => (
                            <Link className='underline hover:animate-pulse ml-2' href='/signup'>
                                {text}
                            </Link>
                        ),
                    })}
                </CardFooter>
            </Card>
        </div>
    )
}
